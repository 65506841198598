import {isDevEnvironment} from '@core/configuration';
import {IDispatch, PlasmaReducers, PlasmaState} from '@coveord/plasma-react';
import {
    AnyAction,
    ReducersMapObject,
    Store,
    applyMiddleware,
    combineReducers,
    compose,
    legacy_createStore,
} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import promise from 'redux-promise-middleware';
import {thunk, type ThunkDispatch} from 'redux-thunk';

const createReducerManager = (initialReducers: ReducersMapObject = {}) => {
    // Create an object which maps keys to reducers
    const reducers = new Map<string, any>();

    // add the Plasma reducers by default
    Object.entries({...initialReducers, ...PlasmaReducers}).forEach(([key, reducer]) => reducers.set(key, reducer));

    // Create the initial combinedReducer
    let combinedReducer = combineReducers(Object.fromEntries(reducers));

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove: Set<string> = new Set();

    return {
        getReducerMap: () => Object.fromEntries(reducers),
        reduce: (state, action) => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.size > 0) {
                state = {...state};
                keysToRemove.forEach((key) => {
                    delete state[key];
                });
                keysToRemove = new Set();
            }

            if (action.type === 'RESET_STATE') {
                state = undefined;
            }

            // Delegate to the combined reducer
            return combinedReducer(state, action);
        },

        /**
         * Add one or many reducers to the store
         *
         * @param key either a string or ReducersMapObject
         * @param reducer if the first parameter is a string:` the reducer, undefined otherwise
         */
        add: (key: string | ReducersMapObject, reducer?: any) => {
            if (!key) {
                throw new Error('You must specify a key.');
            }
            if (typeof key === 'string' && reducers.get(key)) {
                console.warn(`The reducer ${key} already exists in the store. Skipping registration.`);
                return;
            }

            if (typeof key === 'string') {
                // Add the reducer to the reducer mapping
                reducers.set(key, reducer);
            } else {
                Object.entries(key).forEach(([reducerKey, reducerFunction]) => {
                    if (reducers.get(reducerKey)) {
                        console.warn(`The reducer ${reducerKey} already exists in the store. Skipping registration.`);
                        return;
                    }
                    reducers.set(reducerKey, reducerFunction);
                });
            }

            // Generate a new combined reducer
            combinedReducer = combineReducers(Object.fromEntries(reducers));
        },

        /**
         * Remove one or many reducers from the store
         *
         * @param key a string or string[] of the reducers key(s) to remove
         */
        remove: (key: string | string[]) => {
            if (!key) {
                throw new Error('You must specify a key.');
            }

            const keys: string[] = typeof key === 'string' ? [key] : key;
            keys.forEach((reducerKey: string) => {
                if (!reducers.get(reducerKey)) {
                    throw new Error(`The reducer ${reducerKey} does not exist in the store.`);
                }

                // Remove it from the reducer mapping
                reducers.delete(reducerKey);

                // Add the key to the list of keys to clean up
                keysToRemove.add(reducerKey);
            });

            // Generate a new combined reducer
            combinedReducer = combineReducers(Object.fromEntries(reducers));
        },
    };
};

const middlewares = [thunk, promise];
const composeEnhancers = isDevEnvironment() ? composeWithDevTools({name: 'admin-ui'}) : compose;

/**
 * Generates a new store with reducers and initial state.
 * In the admin-ui only one store should exist but this method can be useful in UTs
 *
 * @param reducers initial reducers to add to the store
 * @param initialState default state of the application
 */
export const generateStore = <T>(reducers?: ReducersMapObject, initialState?: T & PlasmaState) => {
    const manager = createReducerManager(reducers);
    const store = legacy_createStore(
        manager.reduce,
        initialState,
        composeEnhancers(applyMiddleware<IDispatch>(...middlewares)),
    );
    return {manager, store};
};

const {manager: reducerManager, store: globalStore} = generateStore();

/**
 * Return the Redux store of the application.
 * This method is generic, use the generic parameter to type the state
 *
 * @example const store = getStore<{myProperty: number}>(); // store.getState().myProperty will exist
 */
export const getStore = <T>(): Store<T & PlasmaState> & {
    dispatch: ThunkDispatch<T & PlasmaState, null, AnyAction>;
} => globalStore;
export const addReducer = reducerManager.add;
export const removeReducer = reducerManager.remove;
