import {getStore} from '@core/store';

export interface Organization {
    id?: string;
    readOnly?: boolean;
    createdDate?: number;
    displayName?: string;
    license?: any;
    owner?: {
        email?: string;
    };
    provisioningStatus?: {
        currentProvisioningProgress: number;
        initialProvisioningDone: boolean;
        lastProvisioningCompletedDate: number;
        ongoing: boolean;
        status: string;
    };
    publicContentOnly?: boolean;
    adminUITimeoutInSeconds?: number;
    type?: string;
    configuration?: {
        servingExperimentAllowed: boolean;
    };
    [key: string]: any;
}

export interface OrganizationState {
    organization?: Organization;
}

export const OrganizationStore = getStore<OrganizationState>();
