import {OrganizationStore} from './store';
import {OrganizationSelectors} from './selectors';
import {OrganizationLocalStorage} from './OrganizationLocalStorage';

const EXTRACT_ORG_ID_FROM_URL_HASH = /#\/(\w+)/;

const getId = () =>
    OrganizationSelectors.getOrganizationId(OrganizationStore.getState()) ||
    EXTRACT_ORG_ID_FROM_URL_HASH.exec(window.location.hash)?.[1] ||
    OrganizationLocalStorage.getOrganizationFromLocalStorage() ||
    null;

export const CurrentOrganization = {
    getId,
};
